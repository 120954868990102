import React from 'react'
import './Creator.css'
import portrait from './images/1323.gif'

export default function Creator() {
  return (
    <div id='creator'>
        <div className='creator'>
          <div className='center'><h2>Meet the creator</h2></div>
          <div className='portrait'><img src={portrait} alt="Creator PFP" /><h3>Warno</h3></div>
          <p>
            Hey ! i am Warno, Kaiju Mfers founder. I first got into cryptos in 2017 and lost interest in it after the big crash.
            My passion for it got reignited in 2021 and discovered NFTs around July. Since then, I got obsessed with them and dreamed about making them my everyday reality.
          </p>
          <p>
            Creating blockchain enabled games as well as usefull tools to help peoples in their adventures is now my number one priority.
            I have no background into programing but pushed by my motivation, I started my journey a couple months ago. I learned a lot from my fellow Kaiju Kingz’s 
            and by diving nose first into other's code. Starting from blockchain and smart contract interactions,
            I now am able to bring you a space where everyone looking to learn more about anything Web3 related can work together in order to help each other.
          </p>
          <p>
            I hope to create a builders centered community where we could use each other’s work and progress as a catalyst to our creativity.
            One of the hardest part about self-teaching new skills is to put what you learn into practice and get constructive feedback to then improve yourself.
            By providing each others with our collective knowledge, we can lay the fondations to what can become something inspiring for all newcomers.
          </p>
        </div>
    </div>
  )
}