import React from 'react'
import './RwasteWarning.css'
import logo from "../images/logo.png";

export default function RwasteWarning({display, close, allowRwaste}) {

  return (
    <div className={display ? "modalBG" : "none"}>
        <div className='rwasteModal'>
            <img src={logo} alt="logo" /><span className="close" onClick={close}>&times;</span>
            <div>
                <h2>Minting with Rwaste</h2>
                <p>Before minting with Rwaste you need to approve the spending. <br />
                Only the required amount will be approved.</p>
            </div>
            <div className='container'><button className="button" onClick={allowRwaste}>Approve Rwaste</button></div>
        </div>
    </div>
  )
}