import React, { useEffect, useState } from 'react';
import { ethers } from "ethers";
import './MintMenu.css';

import kaijuMfersABI from "./kaijuMfersABI.json";
import rwasteABI from "./rwasteABI.json"

import rwaste from '../images/rwaste_logo.png';
import ethereumLogo from '../images/ethereum.png';

import RwasteModal from './RwasteWarning';

export default function MintMenu() {

  //hooks
    const [currentAccount, setCurrentAccount] = useState(0);
    const [quantity, changeQuantity] = useState(1)
    const [maxQuantity, setMaxQuantity] = useState(5);
    const [totalSupply, NewTotalSypply] = useState('NULL');
    const [rwasteModalDisplay, setRwasteModalDisplay] = useState(false);
    const [mintMessage, newMintMessage] = useState("mint")
    const [mintMessageR, newMintMessageR] = useState("mint with rwaste")


  //prices
    const price = 0.0333;
    const rwastePrice = 20;
    const freeSupply = 333;

  //Blockchain setup
    const kmfersContract = "0x997cc81463e4b14d2338a9af8c342ceb76996ab4"
    const rwasteContract = "0x9D13F7789B8ABE77D251e199d26f3EF2b29e92B0"
    const KmfersABI = kaijuMfersABI;
    const RwasteABI = rwasteABI;
    const alchemyAPI = process.env.REACT_APP_alchemyAPI;
    const readProvider = new ethers.providers.AlchemyProvider("homestead", alchemyAPI);
    const readKaijuMfers = new ethers.Contract(kmfersContract, KmfersABI, readProvider);
    const readRwaste = new ethers.Contract(rwasteContract, RwasteABI, readProvider);
    var provider = 0;
    

    //reload page on network change and check if client is on good network
  

    if(currentAccount !== 0){
      provider = new ethers.providers.Web3Provider(window.ethereum, "any")
      provider.on("network", (newNetwork, oldNetwork) => {
        if (oldNetwork) {
          window.location.reload();
        }
      })
    }
    
    const checkNetwork = async () => {
      if(provider !== 0){
        const networkAlert = await provider.getNetwork();
        if (networkAlert.name !== "homestead"){
          window.alert("Make sure to be on Ethereum Mainnet");
        }
      }
    }
    
    let mint = async () => {
      try{
        let cost = totalSupply >= freeSupply ? priceRounding() : "0";
        let signer = provider.getSigner();
        const contract = new ethers.Contract(kmfersContract, kaijuMfersABI, signer);
        const overrides = {
          value: ethers.utils.parseEther(cost.toString())
        };
        var trx = await contract.mint(quantity, overrides);
        newMintMessage("Minting...")
        await trx.wait();
        newMintMessage("Minted " + quantity)
        alert("transaction hash: https://etherscan.io/tx/" + trx.hash)
        newMintMessage("Mint")
      }catch(error){
        console.log(error);
        alert(error)
      }
    }
    
    let mintWithRwaste = async () => {
        let mintR = async () => {
          try{
            let signer = provider.getSigner();
            const contract = new ethers.Contract(kmfersContract, kaijuMfersABI, signer);
            let cost = totalSupply >= freeSupply ? quantity * rwastePrice : 0;
            console.log('cost', cost);
            cost = ethers.utils.parseEther(cost.toString())
            console.log(cost);
            let trx = await contract.mintWithRwaste(quantity, cost);
            newMintMessageR("Minting...")
            await trx.wait();
            newMintMessageR("Minted " + quantity)
            alert("transaction hash: https://etherscan.io/tx/" + trx.hash)
            newMintMessageR("Mint with Rwaste") 
          }catch(error){
            console.log(error);
            alert(error)
          }
        }
        if(totalSupply >= freeSupply){
          await isRwasteAllowed() ? mintR() : setRwasteModalDisplay(true);
        }else{
          mintR();
        }
    }

    async function isRwasteAllowed() {
      try{
        var allowance = await readRwaste.allowance(currentAccount, kmfersContract);
        let isAllowed = ethers.utils.formatEther(allowance) >= quantity * rwastePrice;
        return isAllowed;
      }
      catch (error){
        console.log(error);
      }
    };

    let allowRwaste = async () => {
      try{
        let signer = provider.getSigner();
        const contract = new ethers.Contract(rwasteContract, RwasteABI, signer);
        let value = (quantity * rwastePrice).toString()
        let trx = await contract.increaseAllowance(kmfersContract, ethers.utils.parseEther(value))
        await trx.wait();
        alert("Rwaste successfully aproved at transaction:", trx.hash)
        setRwasteModalDisplay(false);
      }catch (error){
        console.log(error);
      }
    }
  
    async function checkIfWalletIsConnected() {
      try {
        const { ethereum } = window;
  
        if (!ethereum) {
          console.log("Make sure you have metamask!");
          return;
        }
  
        const accounts = await ethereum.request({ method: 'eth_accounts' });
  
        if (accounts.length !== 0) {
          const account = accounts[0];
          setCurrentAccount(account);
        }

      } catch (error) {
        console.log(error);
      }
    }

    const connectWallet = async () => {
      try {
        const { ethereum } = window;
  
        if (!ethereum) {
          alert("Get MetaMask!");
          return;
        }
  
        const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  
        console.log("Connected", accounts[0]);
        setCurrentAccount(accounts[0]);
      } catch (error) {
        console.log(error);
      }
    };

    async function counter() {
      let supply = await readKaijuMfers.totalSupply();
      NewTotalSypply(supply.toNumber());
    }

    const onSubmit = (e) => {
      e.preventDefault();
    }

    let priceRounding = () => {
      let rounded = (Math.round(quantity * price * 10000) / 10000).toString();
      return rounded;
    }

    let Price = () => {
      return(
        <div className='priceDisplay'>
          <span className='left'>Mint:  {quantity}</span>
          <div className='prices'>
          <div><span className='middle'>{totalSupply >= freeSupply ? priceRounding() : "Free"}</span> <img className='currency' src={ethereumLogo} alt='Ethereum'></img></div>
          <div><span className='middle'>{totalSupply >= freeSupply ? quantity * rwastePrice : "Free"}</span> <img className='currency' src={rwaste} alt='Rwaste'></img></div>
          </div>
        </div>
      )
    }

    useEffect(() => {
      checkIfWalletIsConnected();
      counter();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if(totalSupply >= freeSupply){
        setMaxQuantity(10);
      }
      else{
        setMaxQuantity(5);
      }
      checkNetwork()
      // eslint-disable-next-line
    }, [totalSupply]);

  return (
    <section id="mint">
        <form className="form" onSubmit={onSubmit}>
          <header className='header'>
            <h4 className="bio">
              3333 Kaiju Mfers to be minted.<br/>The first 333 are free and then they are {price}E each.<br/>5 per transaction for the free ones then 10 for the rest.
            </h4>
            <h3 className="count">{totalSupply}/3333</h3>
          </header>

          <Price/>
          
          <input className='quantity' type="range" min="1" max={maxQuantity} defaultValue="1" step="1" onChange={(event) => changeQuantity(event.target.value)}/>
          <div className='mintButtons'>
            {!currentAccount ? 
              <div className='container'><button className="button" onClick={connectWallet}>Connect Wallet</button></div> : 
              <>
                <div className='container'>
                  <button className='button' onClick={() => mint()}>{mintMessage}</button>
                </div>
                <div className='container'>
                  <div className='rwaste'></div>
                  <button className='button' onClick={() => mintWithRwaste()}>{mintMessageR}</button>
                  <RwasteModal display={rwasteModalDisplay} close={() => setRwasteModalDisplay(false)} allowRwaste={() => allowRwaste()}/>
                </div>
              </>
            }
          </div>
       </form>
    </section>
  )
}