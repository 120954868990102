import React, { useState } from 'react'
import demo from "./images/demo.gif";
import logo from "./images/logo.png";
import './NavBar.css';

export default function NavBar() {

  const links = [
    {
      id: "main",
      title: "Home"
    },
    {
      id: "mint",
      title: "Mint"
    },
    {
      id: "roadmap",
      title: "Roadmap"
    },
    {
      id: "creator",
      title: "Meet the creator"
    }
  ];

  const [activeLink, setActiveLink] = useState(links[0]["id"]);

  return (
    <div className="navbar">
        <div className="leftvw">
         <a href='#main'><img className="logo" src={logo} alt="logo"></img></a>
         <a href='#main'><img className="demo" src={demo} alt="sneek peek gif"></img></a>
       </div>
       <div className="vw">
        {links.map(({ id, title }) => (
            <a
              key={id}
              className={id === activeLink ? "active" : ""}
              onClick={() => setActiveLink(id)}
              href={`#${id}`}
            >
              {title}
            </a>
        ))}
      </div>
    </div>
  )
}