import React from "react";

import './App.css';
import NavBar from './NavBar';
import MintMenu from './mintapp/MintMenu';
import Roadmap from "./Roadmap";
import Creator from "./Creator";
import Footer from "./Footer";

import animatedLogo from "./images/logo.gif";
import chevron from "./images/chevron.gif";


function App() {

  return (
    <div id="main">
      <NavBar/>

      <div className="home">
        <div className="gradient">
          <img className="animatedLogo" src={animatedLogo} alt="fancy animated logo gif"></img>
          <a href="#roadmap"><img className="chevron" src={chevron} alt="chevron"></img></a>
        </div>
      </div>
      <MintMenu/>
      <Roadmap/>
      <Creator/>
      <Footer/>
    </div>
  );
}

export default App