import React, { useState } from 'react';
import './Roadmap.css';

export default function Roadmap() {

  const [expanded, setExpanded] = useState("");
  const [expanded2, setExpanded2] = useState("");

  let handleExpand = () => {
    if(expanded === "expanded"){
      setExpanded("");
    }else{
      setExpanded("expanded");
    }
  };
  let handleExpand2 = () => {
    if(expanded2 === "expanded"){
      setExpanded2("");
    }else{
      setExpanded2("expanded");
    }
  };

  return (
    <div id='roadmap'>
      <div className='bg'>
        <div className='frame'>
          <h2 className='roadmap-title'><span>R</span>oadmap</h2>
          <div className='content'><div>10%</div>
            <p className='description'>End of the freesupplly.<br/>Starting the fund colection for the $Rwaste liquidity pool</p>
          </div>
          <div className='content'><div>30%</div>
            <p className='description'>1st airdrop: 2 Kaiju Mutants will be raffled to the holders</p>
          </div>
          <div className='content'><div>50%</div>
            <p className='description'>2nd airdrop: 1 Kaiju genesis and 1 Baby will be raffled to the holders</p>
          </div>
          <div className='content'><div>75%</div>
            <p className='description'>Secret mission unlocked</p>
          </div>
          <div className='content'><div>100%</div>
            <p className='description'>3rd airdrop: 1 Kaiju genesis, 2 Babies and 5 Mutants will be raffled to the holders.<br/>
            The creator get rewarded</p>
          </div>
        </div>

        <div id='project'>
          <h2 className={expanded} onClick={() => handleExpand()}><span>W</span>hat are Kaiju Mfers ?</h2>
          <div className={expanded}>
            <p>
              Kaiju Mfers will grow the Kaiju kingz $Rwaste liquidity pool by provinding funds to it.
            </p>
            <p>
              There is a total of 3333 Kmfers generated from XXX traits with some animated legendaries 1/1.<br/>
              Each Kaiju Mfer is able to be minted for 0.0333E or 20 $Rwaste.
            </p>
            <p>
              Creating something meaningfull for the comunity was a big part in starting the project. Kaiju Mfers are meant to be a sandbox platform for everyone
              who aim to improve their skills or learn new ones. Having a close knit comunity focused around learning from each other and working with everyone's
              strength is one of the main goals of the project.
            </p>
          </div>
        </div>
        <div id='whatnext'>
          <h2 className={expanded2} onClick={() => handleExpand2()}><span>W</span>hat next ?</h2>
          <div className={expanded2}>
            <p>
              All the collected funds will go towards the $Rwaste liquidity pool and the raffled Kaijus. If we mint out, a percentage (decided by the holders througth a public vote) of the funds will be sent to the creator.
            </p>
            <p>
              Build to be a medium for content release and feedback, the holders will have access to future tools and other content created. Holders will also be able to submit content
              to be published to the comunity in order to benefit from having a usebase and gain real world experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}