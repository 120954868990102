import React from 'react'
import './Footer.css'

import twitter from "./images/Twitter.png";
import discord from "./images/Discord.png";
import looksrare from "./images/LooksRare.png";
import brokensea from "./images/BrokenSea.png";
import logo from "./images/logo.png";

export default function Footer() {
  return (
    <footer>
      <div>
        <div className="socials">
          <a href="https://twitter.com/KaijuMfers" title='twitter'><img src={twitter} alt="twitter logo" /></a>
          <a href="https://twitter.com/KaijuMfers" title='discord'><img src={discord} alt="discord logo" /></a>
          <a href="https://twitter.com/KaijuMfers" title='Looksrare'><img src={looksrare} alt="Looksrare logo" /></a>
          <a href="https://twitter.com/KaijuMfers" title='opensea'><img src={brokensea} alt="Brokensea logo" /></a>
        </div>
        <p><span>Verified smart contract:</span> <a href="https://etherscan.io/address/0x997cc81463e4b14d2338a9af8c342ceb76996ab4" title='etherscan'>0x997cc81463e4b14d2338a9af8c342ceb76996ab4</a><br />Kaiju Mfers, 2022</p>
      </div>
      <img className='footer-logo' src={logo} alt="logo" />
      
    </footer>
  )
}